<template>
  <div class="main-container">
    <h1 class="main-heading">
      <div>{{ "User Level Rewards" }}</div>
      <Button :loading="isLoading" text="Save" :onClick="() => submit()" type="info" />
    </h1>
    <div class="content-container"></div>
    <div class="col">
      <div class="action-buttons-table">
        <div
          v-for="(rewardKey, index) in Object.keys(xpRewards)"
          v-bind:key="index"
        >
          <div class="row header-row">
            <p>{{ `Level ${rewardKey}` }}</p>
            <p>{{ $t(`REWARD_VALUES.REWARD_TYPE`) }}</p>
            <p>{{ $t("REWARD_VALUES.AMOUNT") }}</p>
            <div class="cell">
              <Button text="Add" type="success" size="sm" :loading="false" :onClick="() => addReward(rewardKey)" />
            </div>
          </div>
          <div
            v-for="(reward, rewardIndex) in xpRewards[rewardKey].rewards"
            v-bind:key="rewardIndex"
            class="row"
          >
            <p></p>
            <select
              v-model.number="xpRewards[rewardKey].rewards[rewardIndex].type"
            >
              <option
                v-for="option in constants.REWARD_TYPES"
                :value="option.value"
                v-bind:key="option.value"
              >
                {{ option.text }}
              </option>
            </select>
            <input
              type="number"
              v-model.number="xpRewards[rewardKey].rewards[rewardIndex].amount"
            />
            <div class="cell">
              <Button text="Remove" type="error" size="sm" :loading="false" :onClick="() => removeReward(rewardKey, rewardIndex)" />
            </div>
          </div>
        </div>
      </div>
      <div class="buttons-section">
        <Button
          text="Export to Development"
          type="export"
          v-if="env === 'staging' || env === 'prod'"
          :onClick="() => exportTo('dev')"
          :loading="false"
        />
        <Button
          text="Export to Staging"
          type="export"
          v-if="env === 'dev' || env === 'prod'"
          :onClick="() => exportTo('staging')"
          :loading="false"
        />
        <Button
          text="Export to Production"
          type="export"
          v-if="env === 'staging' || env === 'dev'"
          :onClick="() => exportTo('prod')"
          :loading="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  onMounted,
  computed,
  reactive,
  watch,
  onBeforeMount,
  onBeforeUnmount,
  ref,
} from "vue";
import { useStore } from "vuex";
import dispatchMap from "@/constants/dispatchMap";
import Button from "../../components/common/Button.vue";

export default {
  name: "ExperienceRewards",
  components: {
    Button,
  },
  setup() {
    const constants = {
      REWARD_TYPES: [
        { text: "Gold", value: 0 },
        { text: "Gem", value: 1 },
      ],
    };

    const store = useStore();
    const usedPages = ["experienceRewards"];
    const xpRewards = reactive({});
    const isLoading = ref(false);

    onBeforeMount(() => {
      usedPages.forEach((page) => {
        dispatchMap[page].forEach((dispatchStr) => {
          store.dispatch(dispatchStr);
        });
      });
    });

    const rewards = computed(() => store.getters["road/getExperienceRoad"]);

    onMounted(() => {
      if (rewards.value) {
        const editingReward = JSON.parse(JSON.stringify(rewards.value));
        Object.keys(editingReward).forEach((key) => {
          xpRewards[key] = editingReward[key];
        });
      }
      store.dispatch("loader/loadingStatus", false);
    });
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    watch(
      () => store.getters["road/getExperienceRoad"],
      (rewards) => {
        if (rewards) {
          const editingReward = JSON.parse(JSON.stringify(rewards));
          Object.keys(editingReward).forEach((key) => {
            xpRewards[key] = editingReward[key];
          });
        }
      }
    );

    const submit = () => {
      isLoading.value = true
      store.dispatch("road/updateExperienceRoad", xpRewards).then(() => isLoading.value = false)
    };

    const exportTo = (environment) => {
      store.dispatch("road/exportExperienceRoad", {
        experienceRoad: xpRewards,
        environment: environment,
      });
    };

    const removeReward = (rewardKey, rewardIndex) => {
      xpRewards[rewardKey].rewards.splice(rewardIndex, 1)
    }

    const addReward = (key) => {
      xpRewards[key].rewards.push({ type: 0, amount: 0 });
    };

    const appConfig = computed(() => store.getters["auth/getAppConfig"]);
    const env = appConfig.value.appEnv || "dev";

    return {
      xpRewards,
      submit,
      exportTo,
      env,
      addReward,
      constants,
      removeReward,
      isLoading
    };
  },
};
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
  display: grid;
  grid-template-columns: 6fr 4fr;
}

.form-container {
  display: flex;
  margin: 15px 0;
}

.form-container p {
  width: 60%;
  text-align: start;
}
.form-container input,
.form-container div,
.form-container select {
  width: 40%;
}
#creation_date,
#expiration_date {
  width: 100%;
  height: 100%;
  padding: 0;
}

.buttons-section {
  margin-top: 50px;
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  height: 80px;
}

.buttons-section button {
  font-size: 1.25rem;
}

.cell {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.col {
  padding: 15px;
}
.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
  margin: 10px 0;
}

.row.condition-row {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.row.header-row {
  font-weight: bold;
  border-bottom: 1px solid gray;
}
.form-container.inline {
  display: flex;
  flex-direction: column;
}

.form-info {
  color: gray;
  font-size: 0.75rem;
  text-align: start;
}

.main-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


</style>